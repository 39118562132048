import { useQuery } from "@apollo/react-hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import IS_LOGGED_IN from "../../graphql/queries/login.query";
import { StyledLoginWrapper } from "../../pages/login/styled-login";
import CustomLink from "../customLink/CustomLink";
import Logo from "../logo/logo.component";
import NavItem from "../navigation/nav-item.component";
import Nav from "../navigation/nav.component";
import Sidebar from "../sidebar/sidebar.component";
import { StyledButton } from "../styles/styled-button";

import { StyledHeader, StyledHeaderContent, StyledHeaderWrapper, StyledLoginButtonWrapper } from "./styled-header";

const Header = ({ theme, toggleTheme }) => {

  const { t, ready } = useTranslation(["navigations"], { useSuspense: false });

  const [fixed, setFixed] = useState(false);

  const {
    data: { isLoggedIn }
  } = useQuery(IS_LOGGED_IN);

  let height = 50;
  let scrolled = 0;
  let ticking = false;

  const fixedHeader = (scrolled) => {
    if (scrolled > height) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  window.addEventListener("scroll", function () {
    scrolled = window.pageYOffset;
    if (!ticking) {
      window.requestAnimationFrame(function () {
        fixedHeader(scrolled);
        ticking = false;
      });
      ticking = true;
    }
  });

  return (
    <>
      <StyledHeader id="header" fixed={fixed}>
        <StyledHeaderWrapper className="header__wrapper">
          <StyledHeaderContent className="header__content">
            <Logo fixed={fixed} ready={ready} t={t} />
            <Nav
              theme={theme}
              toggleTheme={toggleTheme}
              isLoggedIn={isLoggedIn}
              ready={ready}
              t={t}
            />
            <div className="header__login">
              {!isLoggedIn ?
                <StyledLoginButtonWrapper fixed={fixed}>
                  <CustomLink to="/login" className="header__login-icon">
                    <span className="wm-icon-enter" />
                  </CustomLink>
                  <StyledButton
                    color="main"
                    size="small"
                    as={CustomLink}
                    to="/login"
                    dataTestId="login-link"
                  >
                    {t("login")}
                  </StyledButton>
                </StyledLoginButtonWrapper>
                :
                <Sidebar fixed={fixed} />
              }
            </div>
          </StyledHeaderContent>
        </StyledHeaderWrapper>
      </StyledHeader>
    </>
  );
};

export default Header;
