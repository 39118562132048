import React from "react";
import { useTranslation } from "react-i18next";

import { StyledFooterBlock, StyledFooterContentItem } from "./styled-footer";

const FooterSocialLinks = () => {

  const { t, ready } = useTranslation("footer", { useSuspense: false });

  return (
    <StyledFooterBlock className="footer-social-links">
      <StyledFooterContentItem className="footer-item footer-social-links__social-list">
        {
          ready &&
          <ul className="footer-item__list social-wrapper">
            <li>
              <a
                href="https://t.me/"
                target="_blank"
                rel="noreferrer"
                aria-label="telegram"
              >
                <span className="wm-icon-telegram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
                aria-label="facebook"
              >
                <span className="wm-icon-facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
                aria-label="twitter"
              >
                <span className="wm-icon-x" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noreferrer"
                aria-label="instagram"
              >
                <span className="wm-icon-instagram" />
              </a>
            </li>
          </ul>
        }
      </StyledFooterContentItem>
    </StyledFooterBlock>
  );
};

export default FooterSocialLinks;