import React from "react";
import { supportedLanguages } from "../utils/consts.util";

const Home = React.lazy(() => import("../pages/home/home.component"));
const AboutUs = React.lazy(() => import("../pages/about-us/about-us.component"));
const Contacts = React.lazy(() => import("../pages/contacts/contacts.component"));
const LoginPage = React.lazy(() => import("../pages/login/login-page.component"));
const RegistrationPage = React.lazy(() => import("../pages/registration/registration.component"));
const EmailConfirmPage = React.lazy(() => import("../pages/email-confirm/email-confirm.component"));
const ForgotPasswordPage = React.lazy(() => import("../pages/forgot-password/forgot-password.component"));
const ChangePasswordPage = React.lazy(() => import("../pages/change-password/change-password.component"));
const News = React.lazy(() => import("../pages/news/news.component"));
const NetworkConfirmationsPage = React.lazy(() => import("../pages/network-confirmations/network-confirmations-page"));
const NewsDetailsPage = React.lazy(() => import("../pages/news/news-details.component"));
const Reviews = React.lazy(() => import("../pages/review/reviews.component"));
const NavInformationPage = React.lazy(() => import("../components/navigation/nav-information-page.component"));
const UseTerms = React.lazy(() => import("../pages/static-information/use-terms.component"));
const CookiesPolicy = React.lazy(() => import("../pages/static-information/cookies-policy.component"));
const PrivacyPolicy = React.lazy(() => import("../pages/static-information/privacy-policy.component"));
const LoyaltyProgramInfo = React.lazy(() => import("../pages/loyalty-program/loyalty-program-info.component"));
// const CryptoDictionary = React.lazy(() => import("../pages/crypto-dictionary/crypto-dictionary.component"));
const ExchangeRegulations = React.lazy(() => import("../pages/static-information/exchange-regulations.component"));
const CardVerificationManual = React.lazy(() => import("../pages/static-information/card-verification-manual.component"));
// const FastCryptocurrencyExchange = React.lazy(() => import("../pages/seo-content/fast-cryptocurrency-exchange"));
// const CryptocurrencyExchange = React.lazy(() => import("../pages/seo-content/cryptocurrency-exchange"));
// const BitcoinWallet = React.lazy(() => import("../pages/seo-content/bitcoin-wallet"));
// const BitcoinRate = React.lazy(() => import("../pages/seo-content/bitcoin-rate"));
// const CitiesPage = React.lazy(() => import("../pages/cities/cities.component"));
const TrafficComponent = React.lazy(() => import("../pages/traffic/traffic.component"));
const NotFoundComponent = React.lazy(() => import("../pages/not-found/not-found.component"));
// const CityDescriptionPage = React.lazy(() => import("../pages/city-description/city-description.page.component"));
const FaqInformation = React.lazy(() => import("../pages/static-information/faq.component"));
const Tariff = React.lazy(() => import("../pages/tariff/tariff.component"));
const RatesPage = React.lazy(() => import("../pages/rates/rates.component"));
const AccountDeletePage = React.lazy(() => import("../pages/account/account-delete.component"));
// const ExchangeDirectionsPage = React.lazy(() => import("../pages/exchange-directions/exchange-directions.component"));
// const P24ToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/p24-to-crypto"));
// const MonobToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/monob-to-crypto"));
// const MonobEURToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/monob-eur-to-crypto"));
// const WiseEURToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/wise-eur-to-crypto"));
// const RevolutEURToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/revolut-eur-to-crypto"));
// const CardToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/card-to-crypto"));
// const CashToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/cash-to-crypto"));
// const AdvcToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/advc-to-crypto"));
// const PmToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/pm-to-crypto"));
// const PrToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/pr-to-crypto"));
// const CryptoToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/crypto-to-crypto"));
// const BtcToTrx = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-trx"));
// const BtcToLtc = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-ltc"));
// const BtcToEth = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-eth"));
// const BtcToDoge = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-doge"));
// const BtcToZec = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-zec"));
// const XrpToUsdt = React.lazy(() => import("../pages/exchange-directions/directions/xrp-to-usdt"));
// const BtcToXrp = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-xrp"));
// const BtcToUsdt = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-usdt"));
// const BountyProgram = React.lazy(() => import("../pages/bounty-program/bounty-program.component"));
// const ScamInfo = React.lazy(() => import("../pages/scam/scam.component"));
const UnsubscribePage = React.lazy(() => import("../pages/unsubscribe/unsubscribe.component"));
const ClientManual = React.lazy(() => import("../pages/static-information/client-manual.component"));
const LoyaltyProgramApprovalPage = React.lazy(() => import("../pages/loyalty-program/loyalty-program-approval.component"));
const LimitsForEurOct = React.lazy(() => import("../pages/static-information/limits-for-eur-oct.component"));
const KycAndAml = React.lazy(() => import("../pages/static-information/kyc-and-aml.component"));
// const OschadToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/oschad-to-crypto"));
// const VisaToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/visa-to-crypto"));
// const PumbToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/pumb-to-crypto"));
// const MastercardToCrypto = React.lazy(() => import("../pages/exchange-directions/directions/mastercard-to-crypto"));
// const BtcToBch = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-bch"));
// const BtcToBsv = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-bsv"));
// const EthToUsdt = React.lazy(() => import("../pages/exchange-directions/directions/eth-to-usdt"));
// const TrxToUsdt = React.lazy(() => import("../pages/exchange-directions/directions/trx-to-usdt"));
// const ZecToEth = React.lazy(() => import("../pages/exchange-directions/directions/zec-to-eth"));
// const DogeToUsdt = React.lazy(() => import("../pages/exchange-directions/directions/doge-to-usdt"));
// const SolToUsdt = React.lazy(() => import("../pages/exchange-directions/directions/sol-to-usdt"));
// const DashToUsdt = React.lazy(() => import("../pages/exchange-directions/directions/dash-to-usdt"));
// const XmrToBtc = React.lazy(() => import("../pages/exchange-directions/directions/xmr-to-btc"));
// const BtcToXtz = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-xtz"));
// const BtcToDash = React.lazy(() => import("../pages/exchange-directions/directions/btc-to-dash"));
// const LtcToUsdt = React.lazy(() => import("../pages/exchange-directions/directions/ltc-to-usdt"));
// const DogeToEth = React.lazy(() => import("../pages/exchange-directions/directions/doge-to-eth"));
// const BudsToTether = React.lazy(() => import("../pages/exchange-directions/directions/buds-to-usdt"));
// const UsdcToTether = React.lazy(() => import("../pages/exchange-directions/directions/usdc-to-usdt"));
// const ZECToTether = React.lazy(() => import("../pages/exchange-directions/directions/zec-to-usdt"));
const IbanVerificationManual = React.lazy(() => import("../pages/static-information/iban-verification-manual.component"));
const EmailVerificationPage = React.lazy(() => import("../pages/registration/emailVerification.component"));
// const Sepa = React.lazy(() => import("../pages/exchange-directions/directions/sepa"));

let locale = "";

Object.entries(supportedLanguages).map(([key, language]) => {
  locale = `${locale}${language.routePrefix}|`
});

const routes = [
  {
    title: "UnsubscribePage",
    path: `/:locale(${locale})?/unsubscribe/:token`,
    component: UnsubscribePage
  },
  {
    title: "RatesPage",
    path: `/:locale(${locale})?/rates`,
    component: RatesPage
  },
  {
    title: "Tariff",
    path: `/:locale(${locale})?/tariff`,
    component: Tariff
  },
  {
    title: "AboutUs",
    path: `/:locale(${locale})?/about-us`,
    component: AboutUs
  },
  {
    title: "LoyaltyProgramInfo",
    path: `/:locale(${locale})?/partners`,
    component: LoyaltyProgramInfo
  },
  // {
  //   title: "BountyProgram",
  //   path: `/:locale(${locale})?/bounty-programma`,
  //   component: BountyProgram
  // },
  // {
  //   title: "ScamInfo",
  //   path: `/:locale(${locale})?/services-to-the-exchange`,
  //   component: ScamInfo
  // },
  {
    title: "Information",
    path: `/:locale(${locale})?/info`,
    component: NavInformationPage
  },
  {
    title: "News",
    path: `/:locale(${locale})?/news`,
    exact: true,
    component: News
  },
  {
    title: "NewsDetails",
    path: `/:locale(${locale})?/news/:metaUrl`,
    component: NewsDetailsPage
  },
  {
    title: "Reviews",
    path: `/:locale(${locale})?/reviews`,
    component: Reviews
  },
  {
    title: "Contacts",
    path: `/:locale(${locale})?/contacts`,
    component: Contacts
  },
  {
    title: "Login",
    path: `/:locale(${locale})?/login`,
    component: LoginPage
  },
  {
    title: "RegistrationPage",
    path: `/:locale(${locale})?/registration`,
    component: RegistrationPage
  },
  {
    title: "EmailVerificationPage",
    path: `/:locale(${locale})?/email-verification/:id`,
    component: EmailVerificationPage
  },
  {
    title: "EmailConfirmPage",
    path: `/:locale(${locale})?/email-confirm`,
    component: EmailConfirmPage
  },
  {
    title: "ForgotPasswordPage",
    path: `/:locale(${locale})?/forgot-password`,
    component: ForgotPasswordPage
  },
  {
    title: "ChangePasswordPage",
    path: `/:locale(${locale})?/change-password/:token?`,
    component: ChangePasswordPage
  },
  {
    title: "UseTerms",
    path: `/:locale(${locale})?/useterms`,
    component: UseTerms
  },
  {
    title: "CookiesPolicy",
    path: `/:locale(${locale})?/cookies-policy`,
    component: CookiesPolicy
  },
  {
    title: "PrivacyPolicy",
    path: `/:locale(${locale})?/privacy`,
    component: PrivacyPolicy
  },
  // {
  //   title: "CryptoDictionary",
  //   path: `/:locale(${locale})?/crypto-dictionary`,
  //   component: CryptoDictionary
  // },
  {
    title: "ExchangeRegulations",
    path: `/:locale(${locale})?/exchange-regulations`,
    component: ExchangeRegulations
  },
  {
    title: "FaqInformation",
    path: `/:locale(${locale})?/faq`,
    component: FaqInformation
  },
  {
    title: "CardVerificationManual",
    path: `/:locale(${locale})?/card-verification-manual`,
    component: CardVerificationManual
  },
  {
    title: "LimitsForEurOct",
    path: `/:locale(${locale})?/limits-for-eur-visa-and-mastercard`,
    component: LimitsForEurOct
  },
  {
    title: "KycAndAml",
    path: `/:locale(${locale})?/kyc-and-aml`,
    component: KycAndAml
  },
  // static seo page
  // {
  //   title: "BitcoinRate",
  //   path: `/:locale(${locale})?/bitcoin-price`,
  //   component: BitcoinRate
  // },
  // {
  //   title: "FastCryptocurrencyExchange",
  //   path: `/:locale(${locale})?/cryptocurrency-exchange`,
  //   component: FastCryptocurrencyExchange
  // },
  // {
  //   title: "CryptocurrencyExchange",
  //   path: `/:locale(${locale})?/buy-cryptocurrency`,
  //   component: CryptocurrencyExchange
  // },
  // {
  //   title: "BitcoinWallet",
  //   path: `/:locale(${locale})?/bitcoin-wallet`,
  //   component: BitcoinWallet
  // },
  // {
  //   title: "Cities",
  //   path: `/:locale(${locale})?/cities`,
  //   component: CitiesPage
  // },
  // {
  //   title: "CityExchange",
  //   path: `/:locale(${locale})?/city/:cityUrl`,
  //   component: CityDescriptionPage
  // },
  {
    title: "Трафик",
    path: `/:locale(${locale})?/traffic/:traffic`,
    exact: true,
    component: TrafficComponent
  },
  // {
  //   title: "Направления обмена",
  //   path: `/:locale(${locale})?/all-exchange-pairs`,
  //   component: ExchangeDirectionsPage
  // },
  // {
  //   title: "P24 to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-privatbank-uah`,
  //   component: P24ToCrypto
  // },
  // {
  //   title: "OshhadBank to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-oshhadbank-uah`,
  //   component: OschadToCrypto
  // },
  // {
  //   title: "Visa to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-visa-eur`,
  //   component: VisaToCrypto
  // },
  // {
  //   title: "Pumb to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-pumb-uah`,
  //   component: PumbToCrypto
  // },
  // {
  //   title: "Mastercard to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-mastercard-eur`,
  //   component: MastercardToCrypto
  // },
  // {
  //   title: "Monob to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-monobank-uah`,
  //   component: MonobToCrypto
  // },
  // {
  //   title: "Monobank EUR to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-monobank-eur`,
  //   component: MonobEURToCrypto
  // },
  // {
  //   title: "Wise EUR to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-wise-eur`,
  //   component: WiseEURToCrypto
  // },
  // {
  //   title: "Revolute EUR to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-revolut-eur`,
  //   component: RevolutEURToCrypto
  // },
  // {
  //   title: "Card to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-visa-mastercard-uah`,
  //   component: CardToCrypto
  // },
  // {
  //   title: "Cash to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-cash`,
  //   component: CashToCrypto
  // },
  // {
  //   title: "Advc to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-advcash-usd`,
  //   component: AdvcToCrypto
  // },
  // {
  //   title: "Payeer to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-payeer-usd`,
  //   component: PrToCrypto
  // },
  // {
  //   title: "Perfect money to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-perfect-money-usd`,
  //   component: PmToCrypto
  // },
  // {
  //   title: "Sepa to crypto",
  //   path: `/:locale(${locale})?/exchange-btc-sepa`,
  //   component: Sepa
  // },
  // {
  //   title: "Crypto to crypto",
  //   path: `/:locale(${locale})?/exchange-crypto-to-crypto`,
  //   component: CryptoToCrypto
  // },
  // {
  //   title: "BTC to BCH",
  //   path: `/:locale(${locale})?/exchange-btc-to-bch`,
  //   component: BtcToBch
  // },
  // {
  //   title: "BTC to BSV",
  //   path: `/:locale(${locale})?/exchange-btc-to-bsv`,
  //   component: BtcToBsv
  // },
  // {
  //   title: "ETH to USDT",
  //   path: `/:locale(${locale})?/exchange-eth-to-usdt`,
  //   component: EthToUsdt
  // },
  // {
  //   title: "ETH to USDT",
  //   path: `/:locale(${locale})?/exchange-trx-to-usdt`,
  //   component: TrxToUsdt
  // },
  // {
  //   title: "ZEC to ETH",
  //   path: `/:locale(${locale})?/exchange-zec-to-eth`,
  //   component: ZecToEth
  // },
  // {
  //   title: "DOGE to USDT",
  //   path: `/:locale(${locale})?/exchange-doge-to-usdt`,
  //   component: DogeToUsdt
  // },
  // {
  //   title: "SOL to USDT",
  //   path: `/:locale(${locale})?/exchange-sol-to-usdt`,
  //   component: SolToUsdt
  // },
  // {
  //   title: "DASH to USDT",
  //   path: `/:locale(${locale})?/exchange-dash-to-usdt`,
  //   component: DashToUsdt
  // },
  // {
  //   title: "XMR to BTC",
  //   path: `/:locale(${locale})?/exchange-xmr-to-btc`,
  //   component: XmrToBtc
  // },
  // {
  //   title: "BTC to XTZ",
  //   path: `/:locale(${locale})?/exchange-btc-to-xtz`,
  //   component: BtcToXtz
  // },
  // {
  //   title: "BTC to DASH",
  //   path: `/:locale(${locale})?/exchange-btc-to-dash`,
  //   component: BtcToDash
  // },
  // {
  //   title: "LTC to USDT",
  //   path: `/:locale(${locale})?/exchange-ltc-to-usdt`,
  //   component: LtcToUsdt
  // },
  // {
  //   title: "DOGE to ETH",
  //   path: `/:locale(${locale})?/exchange-doge-to-eth`,
  //   component: DogeToEth
  // },
  // {
  //   title: "BUDS to Tether",
  //   path: `/:locale(${locale})?/exchange-busd-to-usdt`,
  //   component: BudsToTether
  // },
  // {
  //   title: "USDC to USDT",
  //   path: `/:locale(${locale})?/exchange-usdc-to-usdt`,
  //   component: UsdcToTether
  // },
  // {
  //   title: "ZEC to USDT",
  //   path: `/:locale(${locale})?/exchange-zec-to-usdt`,
  //   component: ZECToTether
  // },
  // {
  //   title: "BTC to TRX",
  //   path: `/:locale(${locale})?/exchange-btc-to-trx`,
  //   component: BtcToTrx
  // },
  // {
  //   title: "BTC to ETH",
  //   path: `/:locale(${locale})?/exchange-btc-to-eth`,
  //   component: BtcToEth
  // },
  // {
  //   title: "BTC to LTC",
  //   path: `/:locale(${locale})?/exchange-btc-to-ltc`,
  //   component: BtcToLtc
  // },
  // {
  //   title: "BTC to DOGE",
  //   path: `/:locale(${locale})?/exchange-btc-to-doge`,
  //   component: BtcToDoge
  // },
  // {
  //   title: "BTC to ZEC",
  //   path: `/:locale(${locale})?/exchange-btc-to-zec`,
  //   component: BtcToZec
  // },
  // {
  //   title: "XRP to USDT",
  //   path: `/:locale(${locale})?/exchange-xrp-to-usdt`,
  //   component: XrpToUsdt
  // },
  // {
  //   title: "BTC to XRP",
  //   path: `/:locale(${locale})?/exchange-btc-to-xrp`,
  //   component: BtcToXrp
  // },
  // {
  //   title: "BTC to USDT",
  //   path: `/:locale(${locale})?/exchange-btc-to-usdt`,
  //   component: BtcToUsdt
  // },
  {
    title: "Home",
    path: `/:locale(${locale})?/:id(.refToken=[a-z-_0-9]*&cur_from=[A-Za-z0-9]*&cur_to=[A-Za-z0-9]*|[a-z-0-9@&]*-to-[a-z-0-9@&]*)?`, //hrivna-uah-visa-to-hcrypto-bsv-bsv  ([a-z-%\(\w+\)]*-to-[a-z-%\(\w+\)]*)? //[a-z-0-9@&]*-to-[a-z-0-9@&]*
    exact: true,
    component: Home
  },
  {
    title: "Client Manual",
    path: `/:locale(${locale})?/client-manual`,
    component: ClientManual
  },
  {
    title: "Network Confirmations",
    path: `/:locale(${locale})?/network-confirmations`,
    component: NetworkConfirmationsPage
  },
  {
    title: "404",
    path: `/:locale(${locale})?/404`,
    exact: true,
    component: NotFoundComponent
  },
  {
    title: "LoyaltyProgramApprovalPage",
    path: `/:locale(${locale})?/approve-referral-requisition/:token?`,
    component: LoyaltyProgramApprovalPage
  },
  {
    title: "IBANVerificationManual",
    path: `/:locale(${locale})?/iban-verification-manual`,
    component: IbanVerificationManual
  },
  {
    title: "AccountDeletePage",
    path: `/:locale(${locale})?/account-delete`,
    component: AccountDeletePage
  }
];

export default routes;
