import styled from "styled-components";

export const StyledTitleWrapper = styled.div`
  max-width: ${({ fullSize }) => fullSize ? "100%" : "620px"};
  margin: 0 auto ${({ mb }) => mb ? `${mb}px` : "40px"};
  text-align: ${({ align }) => align ? align : "center"};
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 1;

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
    padding: 25px 0 15px;
  }

  @media screen and (max-width: 576px) {
    padding: 15px 0;
    gap: 18px;
  }
`;

export const StyledTitle = styled.div`
  color: ${({ theme }) => theme.neutralColors.first};
  font-size: 40px;
  line-height: 1.36;
  font-weight: ${({ weight }) => weight ? weight : 600};

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
  
  @media screen and (max-width: 576px) {
    font-size: 22px;
  }
`;

export const StyledTitleDescription = styled.p`
  color: ${({ theme }) => theme.neutralColors.third};
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;

  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

export const StyledSubTitle = styled.h6`
  color: ${({ theme }) => theme.defaultColor};
  font-family: 'Overpass Mono', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;

  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
`;
