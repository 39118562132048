import styled from "styled-components";
import { StyledSkeletonBg } from "../styles/styled-skeleton-bg";

export const StyledNavigation = styled.nav`
  height: 100%;
  line-height: 19px;
  display: flex;
  align-items: center;
  z-index: 99;

  @media screen and (max-width: 992px) {
    flex-direction: row-reverse;
  }
`;

export const StyledHoverNavigation = styled.div`
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: opacity .3s ease, left .3s ease;
  z-index: 1100;

  div {
    height: 4px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.defaultColor};
    transition: opacity .2s ease;
  }
  
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const StyledMenuList = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 44px;
  position: relative;

  @media screen and (max-width: 992px) {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 10px 0;
    color: ${({ theme }) => theme.header.mobileText};
    background-color: ${({ theme }) => theme.header.mobileBackgroundColor};
    border-top: 1px solid ${({theme}) => theme.navBarBorder};
    justify-content: space-around;
    gap: initial;
    position: fixed;
    left: 0;
    bottom: 0;
    
    .blog-link {
      order: 4
    }
    
    .contacts-link {
      order: 5;
    }
  }

  .login-link {
    padding-right: 0;
  }

  .information-mobile {
    display: none;
    @media screen and (max-width: 992px) {
      display: flex;
    }
  }
`;

export const StyledMenuItem = styled.li`
  height: 100%;
  display: flex;
  align-items: center;

  .menu-link {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__icon {
      padding: 10px 0;
      font-size: 32px;
      text-align: center;
      display: none;
      @media screen and (max-width: 992px) {
        display: flex;
        opacity: 0.75;
      }
      @media screen and (max-width: 768px) {
        font-size: 28px;
      }
      @media screen and (max-width: 576px) {
        padding: 5px 0;
      }
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      @media screen and (max-width: 992px) {
        opacity: 0.75;
      }
      @media screen and (max-width: 768px) {
        font-size: 14px;
        font-weight: 400;
        text-transform: inherit;
      }
      @media screen and (max-width: 576px) {
        font-size: 12px;
      }
    }

    &_current {
      position: relative;
      
      &::after {
        content: '';
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.defaultColor};
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all .3s ease;
        z-index: 1100;
      }
      
      @media screen and (max-width: 992px) {
        .menu-link__icon, .menu-link__title {
          color: ${({ theme }) => theme.header.mobileText};
          opacity: 1;
        }
        
        &::after {
          display: none;
        }
      }
    }

    &:hover {
      .menu-link__icon, .menu-link__title {
        opacity: 1;
      }
    }

    @media screen and (max-width: 992px) {
      &::after {
        display: none;
      }
    }
  }
`;

export const StyledDropdownMenuItem = styled.li`
  height: 90%;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  
  &:after {
    content: "\\e928";
    margin-left: 5px;
    color: ${({ theme }) => theme.header.text};
    font-family: 'wm-theme-icons', serif;
    font-size: 20px;
  }
  
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const StyledLoadNavItem = styled(StyledSkeletonBg)`
  ${({ width }) => width ? `width: ${width}px` : "width: 100%"};
  opacity: 0.5;
`;