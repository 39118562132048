const light = {
  body: "#FAF9F9",
  bgElements: "#F8F8F8",
  borderElements: "#CDCDCD",
  lightBg: "#FCFCFC",
  text: "#4A4E56",
  textOpacity: "rgba(32, 32, 32, 0.40)",
  textReadonly: "rgba(32, 32, 32, 0.7)",
  textReadonlyHex: "#2020201A",
  defaultColor: "#1885F2",
  switchPosition: "1px",
  navBarBg: "#F5F5F5",
  navBarBorder: "#CDCDCD",
  border: "#EFEFEF",
  activeInputBorder: "#202020",
  requisitionStatusDefault: "#e9e9e9",
  hoverColor: "#EDF5FE",
  hoverShadow: "#c9c9c9", // TODO: TEMPORARY
  skeletonBg: "rgba(0, 0, 0, 0.11)",
  skeletonBgGradient: "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent)",
  loadingForm: "rgba(250, 250, 255, 0.5)",
  errorRow: "#fbc8c8",
  disabledText: "rgba(32,32,32,0.75)",
  white: '#ffffff',
  developedBg: '#ECECEC',
  backgroundDark: "#171717",
  primaryColors: {
    first: "#1885F2",
    second: "#349AFF"
  },
  neutralColors: {
    first: "#1C274C",
    second: "#4A4E56",
    third: "#646464",
    fourth: "#F2F2F2",
    fifth: "#FFFFFF",
    sixth: "rgba(242, 242, 242, 0.2)",
    text: {
      first: "#1C274C",
      third: "#7D7D7D"
    },
    bg: {
      fifth: "#F2F2F2",
    }
  },
  dangerColors: {
    first: "#E82020",
    second: "#FF6E65",
    third: "rgba(250, 89, 79, 0.2)",
    fourth: "#FEDEDC",
    fifth: "#FDEDED"
  },
  successColors: {
    first: "#12BB74",
    second: "#42C888",
    third: "rgba(25, 190, 111, 0.2)",
    fourth: "#D1F2E2",
    fifth: "#ECFAF4"
  },
  warningColors: {
    first: "#FFB800",
    second: "#FFAB2E",
    third: "rgba(255, 153, 0, 0.2)",
    fourth: "#FFEBCC",
    fifth: "#FFF9EB"
  },
  infoColors: {
    first: "#338DE2",
    second: "#42A3FF",
    third: "rgba(66, 163, 255, 0.2)",
    fourth: "#D9EDFF",
    fifth: "#EFF6FD",
    sixth: "rgba(51,141,226,0.2)"
  },
  hoverColors: {
    first: "#349AFF",
    second: "#E7EEF3",
    third: "#E5EDF3",
    fourth: "rgba(4, 96, 169, 0.2)",
    fifth: "rgba(100, 100, 100, 0.08)"
  },
  /* Header colors */
  header: {
    backgroundColor: "#171717",
    mobileBackgroundColor: "#F2F2F2",
    text: "#F2F2F2",
    mobileText: "#1C274C",
    linkColor: "#F2F2F2",
    profileBackground: "rgba(24, 133, 242, 0.1)",
    boxShadow: "rgba(4, 96, 169, 0.12)"
  },
  /* Scrollbar background */
  scrollbarBackground: "#CCCCCC",
  /* Images box-shadow */
  imageBoxShadow: "rgba(4, 96, 169, 0.14)",
  /* Scroll to top */
  scrollToTopBorder: "#FAF9F9",
  scrollToTopShadow: "rgba(24, 133, 242, 0.25)",
  scrollToTopHoverShadow: "rgba(24, 133, 242, 0.5)",
  /* Different blocks background */
  blockBackground: "#F2F2F2",
  selectionColors: "rgba(4, 96, 169, 1)"
};

export default light;