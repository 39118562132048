import React from "react";
import { Trans } from "react-i18next";

import CustomLink from "../customLink/CustomLink";

import { StyledFooterExploreBlock } from "./styled-footer";
import { StyledButton } from "../styles/styled-button";
import { NavLink, useLocation } from "react-router-dom";
import i18n from "i18next";
import { defaultLanguage } from "../../utils/consts.util";

const FooterDescription = ({ t, ready, authenticated }) => {
  const language = i18n.language === defaultLanguage ? "/" : "/" + i18n.language + "/";
  const location = useLocation();

  return (
    <StyledFooterExploreBlock>
      <div className="explore-block__wrapper">
        <div className="explore-block__icon-wrapper">
          <span className="wm-icon-footer-icon" />
        </div>
        <h3 className="explore-block__main-title">{ready && t("exploreCryptoWorld")}</h3>
        <p className="explore-block__text">
          {ready && t("exploreCryptoWorldText")}
        </p>
      </div>
      <div className="join-crypto__wrapper">
        <p className="join-crypto__text">{ready && t("joinCryptoWorld")}</p>
        <StyledButton
          exact
          to={
            authenticated
              ? {
                pathname: `${language}`,
                state: {
                  scrollToTop: true,
                  scrollCalc: true,
                  scrollDelay: location.pathname === language ? 250 : 1500,
                  pathNameTest: location.pathname
                }
              }
              : "/registration"
          }
          as={NavLink}
          className="join-crypto__btn"
          color="main"
        >
          {ready && t(authenticated ? "exchangeBtn" : "registerBtn")}
        </StyledButton>
        <p className="join-crypto__subtext">
          {ready && <Trans components={{ CustomLink: <CustomLink to="/privacy" /> }}>
            {t("registerAgreeText")}
          </Trans>}
        </p>
      </div>
    </StyledFooterExploreBlock>
  );
};

export default FooterDescription;