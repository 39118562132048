import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import BadgeNotificationClientDocumentVerified
  from "../badge-notifications/badge-notification-client-document-verified.component";

import Can from "../can/can.component";
import SidebarItem from "./sidebar-itemet.component";
import { useTranslation } from "react-i18next";

import { GET_USER_RBAC_DETAILS } from "../../graphql/queries/user.query";
import { cardVerification, payoutRequisitions, requisition, sidebar } from "../../rbac-consts";
import BadgeNotificationFeedback from "../badge-notifications/badge-notification-feedback.component";
import BadgeNotificationCreditCard from "../badge-notifications/badge-notification-credit-card.component";
import BadgeNotificationReview from "../badge-notifications/badge-notification-review.component";
import BadgeNotificationRequisition from "../badge-notifications/badge-notification-requisition.component";
import { StyledItemWithBadge } from "./styled-sidebar";
import BadgeNotificationReferralRequisitions
  from "../badge-notifications/badge-notification-referral-requisitions.component";
import { userRoles } from "../../utils/consts.util";
import BadgeNotificationClientIban from "../badge-notifications/badge-notification-client-iban.component";
import CustomLink from "../customLink/CustomLink";

const SidebarLinks = ({ handleChange, visible, isBank }) => {
  const { t, ready } = useTranslation("sideBar", { useSuspense: false });

  const client = useApolloClient();
  const { userRole, userId } = client.readQuery({
    query: GET_USER_RBAC_DETAILS
  });

  if (!ready) {
    return null;
  }

  return (
    <>
      <Can
        role={userRole}
        perform={sidebar.REQUISITIONS}
        yes={() => (
          <StyledItemWithBadge>
            <SidebarItem
              as={CustomLink}
              to="/panel/requisitions"
              icon="coin-selection"
              linkTitle={t("requests")}
              handleChange={handleChange}
              dataTestId="sidebar-requisitions"
            />
            <Can
              role={userRole}
              perform={requisition.NOTIFICATION}
              yes={() => <BadgeNotificationRequisition />}
            />
          </StyledItemWithBadge>
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.CURRENCIES}
        yes={() => (
          <SidebarItem
            as={CustomLink}
            to="/panel/currencies"
            icon="exchange-directions"
            linkTitle={t("currencies")}
            handleChange={handleChange}
            dataTestId="sidebar-currencies"
          />
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.PAYMENT_SETTINGS}
        yes={() => (
          <SidebarItem
            as={CustomLink}
            to="/panel/payment-settings"
            icon="payment-systems"
            linkTitle={t("paymentSystems")}
            handleChange={handleChange}
            dataTestId="sidebar-payment-systems"
          />
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.LOYALTY_PROGRAM}
        yes={() => (
          <StyledItemWithBadge>
            <SidebarItem
              as={CustomLink}
              to="/panel/loyalty-program"
              icon="partner-program"
              linkTitle={t("loyality")}
              handleChange={handleChange}
              dataTestId="sidebar-loyalty-program"
            />
            <Can
              role={userRole}
              perform={payoutRequisitions.NOTIFICATION}
              yes={() => <BadgeNotificationReferralRequisitions />}
            />
          </StyledItemWithBadge>
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.BANK_DETAILS}
        yes={() => (
          <SidebarItem
            as={CustomLink}
            to="/panel/bank-details"
            icon="buy-cryptocurrency"
            linkTitle={t("requisites")}
            handleChange={handleChange}
            dataTestId="sidebar-bank-details"
          />
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.CLIENTS}
        yes={() => (
          <SidebarItem
            as={CustomLink}
            to="/panel/clients"
            icon="clients"
            linkTitle={t("clients")}
            handleChange={handleChange}
            dataTestId="sidebar-clients"
          />
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.MANAGERS}
        yes={() => (
          <SidebarItem
            as={CustomLink}
            to="/panel/admins"
            icon="number-confirmed"
            linkTitle={t("admins")}
            handleChange={handleChange}
            dataTestId="sidebar-admins"
          />
        )}
      />
      {!(userRole === userRoles.ROLE_MANAGER && !isBank) &&
        <>
          <Can
            role={userRole}
            perform={sidebar.CARD_VERIFICATION}
            yes={() => (
              <StyledItemWithBadge>
                <SidebarItem
                  as={CustomLink}
                  to="/panel/card-verification"
                  icon="cryptocurrency-exchange"
                  linkTitle={t("cardVerification")}
                  handleChange={handleChange}
                  dataTestId="sidebar-card-verification"
                />
                <Can
                  role={userRole}
                  perform={cardVerification.NOTIFICATION}
                  yes={() => <BadgeNotificationCreditCard />}
                />
              </StyledItemWithBadge>
            )}
          />
          <Can
            role={userRole}
            perform={sidebar.IBAN_VERIFICATION}
            yes={() => (
              <StyledItemWithBadge>
                <SidebarItem
                  as={CustomLink}
                  to="/panel/iban-verification"
                  icon="iban-verification"
                  linkTitle={t("IBANVerification")}
                  handleChange={handleChange}
                  dataTestId="sidebar-iban-verification"
                />
                <Can
                  role={userRole}
                  perform={cardVerification.NOTIFICATION}
                  yes={() => <BadgeNotificationClientIban />}
                />
              </StyledItemWithBadge>
            )}
          />
        </>
      }
      <Can
        role={userRole}
        perform={sidebar.DOCUMENTS}
        yes={() => (
          <StyledItemWithBadge>
            <SidebarItem
              as={CustomLink}
              to="/panel/documents"
              icon="documents-verification"
              linkTitle={t("documentVerification")}
              handleChange={handleChange}
              className="sidebar-link document-verification"
              dataTestId="sidebar-document-verification"
            />
            <BadgeNotificationClientDocumentVerified
              t={t}
              userId={userId}
              visible={visible}
            />
          </StyledItemWithBadge>
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.NEWS}
        yes={() => (
          <SidebarItem
            as={CustomLink}
            /* TODO: uncomment after full integration of Wordpress news */
            // to="/wp-admin"
            // target="_blank"
            to="/panel/news"
            icon="articles"
            linkTitle={t("articles")}
            handleChange={handleChange}
            dataTestId="sidebar-news"
          />
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.REVIEWS}
        yes={() => (
          <StyledItemWithBadge>
            <SidebarItem
              as={CustomLink}
              to="/panel/reviews"
              icon="feedback"
              linkTitle={t("reviews")}
              handleChange={handleChange}
              dataTestId="sidebar-reviews"
            />
            <BadgeNotificationReview />
          </StyledItemWithBadge>
        )}
      />
      {/*<Can*/}
      {/*  role={userRole}*/}
      {/*  perform={sidebar.CITIES_DETAILS}*/}
      {/*  yes={() => (*/}
      {/*    <SidebarItem*/}
      {/*      as={CustomLink}*/}
      {/*      to="/panel/city-details"*/}
      {/*      icon="city"*/}
      {/*      linkTitle={t("cities")}*/}
      {/*      handleChange={handleChange}*/}
      {/*      dataTestId="sidebar-cities"*/}
      {/*    />*/}
      {/*  )}*/}
      {/*/>*/}
      <Can
        role={userRole}
        perform={sidebar.CITIES_CONTACTS}
        yes={() => (
          <SidebarItem
            as={CustomLink}
            to="/panel/city-contacts"
            icon="contacts"
            linkTitle={t("contacts")}
            handleChange={handleChange}
            dataTestId="sidebar-contacts"
          />
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.FEEDBACKS}
        yes={() => (
          <StyledItemWithBadge>
            <SidebarItem
              as={CustomLink}
              to="/panel/feedbacks"
              icon="contact-us"
              linkTitle={t("feedback")}
              handleChange={handleChange}
              dataTestId="sidebar-feedbacks"
            />
            <BadgeNotificationFeedback />
          </StyledItemWithBadge>
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.TEMPLATES}
        yes={() => (
          <StyledItemWithBadge>
            <SidebarItem
              as={CustomLink}
              to="/panel/templates"
              icon="answer-templates"
              linkTitle={t("templatesAnswers")}
              handleChange={handleChange}
              dataTestId="sidebar-answer-templates"
            />
          </StyledItemWithBadge>
        )}
      />
      {/*<Can*/}
      {/*  role={userRole}*/}
      {/*  perform={sidebar.MAILING}*/}
      {/*  yes={() => (*/}
      {/*    <SidebarItem*/}
      {/*      as={CustomLink}*/}
      {/*      to="/panel/mailing"*/}
      {/*      icon="newsletter"*/}
      {/*      linkTitle={t("mailingList")}*/}
      {/*      handleChange={handleChange}*/}
      {/*      dataTestId="sidebar-mailing"*/}
      {/*    />*/}
      {/*  )}*/}
      {/*/>*/}
      <Can
        role={userRole}
        perform={sidebar.REPORTS}
        yes={() => (
          <SidebarItem
            as={CustomLink}
            to={userRole !== userRoles.ROLE_MANAGER ? "/panel/reports" : "/panel/reports-manager-details/" + userId}
            icon="analitycs-report"
            linkTitle={t("reports")}
            handleChange={handleChange}
            dataTestId="sidebar-reports"
          />
        )}
      />
      {/*<Can
        role={userRole}
        perform={sidebar.ARCHIVE}
        yes={() => (parseInt(process.env.REACT_APP_DATE_OF_LAST_REQUISITION) >= createdAt &&
          <SidebarItem
            as={CustomLink}
            to="/panel/old-requisitions"
            icon="book"
            linkTitle={t("archive")}
            handleChange={handleChange}
          />
        )}
      />*/}
      <Can
        role={userRole}
        perform={sidebar.LOGS}
        yes={() => (
          <SidebarItem
            as={CustomLink}
            to="/panel/logs"
            icon="logs"
            linkTitle={t("logs")}
            handleChange={handleChange}
            dataTestId="sidebar-logs"
          />
        )}
      />
      <Can
        role={userRole}
        perform={sidebar.MANUAL}
        yes={() => (
          <SidebarItem
            as={CustomLink}
            to={userRole === userRoles.ROLE_ADMIN ? "/panel/manual/admin" : userRole === userRoles.ROLE_MANAGER ? "/panel/manual/manager" : "/panel/manual/seo"}
            icon="help"
            linkTitle={t("manual")}
            handleChange={handleChange}
            dataTestId="sidebar-faq"
          />
        )}
      />
    </>
  );
};

export default SidebarLinks;
