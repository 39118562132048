import styled from "styled-components";
import { StyledSelect } from "../../styles/styled-img-select";

export const StyledLanguageSelect = styled(StyledSelect)`
  height: 100%;
  width: 45px;
  margin-left: 44px;
  display: flex;
  align-items: center;
  cursor: default;

  input {
    display: none;
  }

  .lang-select {
    width: 100%;
    height: 100%;
    padding: 0;
    color: ${({ theme }) => theme.header.text};
    z-index: 1;
    line-height: inherit;
    position: relative;
    transition: inherit;
    cursor: pointer;

    .rc-select-selector {
      min-height: 93%;
      padding-top: 4px;
      display: flex;
      align-items: center;
    }
    
    .rc-select-selection-search {
      height: 20px;
      cursor: pointer;
    }

    .rc-select-selection-item {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .rc-select-arrow {
      width: 15px;
      height: 15px;
      top: calc(50% - 2px);
      transform: translateY(-50%);

      .rc-select-arrow-icon {
        font-size: 12px;
        top: 0;
        left: 0;

        &:before {
          width: 12px;
          height: 12px;
          color: ${({ theme }) => theme.header.text};
          background-color: transparent;
          border: none;
        }
      }
    }
  }

  .rc-select-dropdown {
    border: none;
    color: ${({ theme }) => theme.header.linkColor};
    background-color: ${({ theme }) => theme.header.backgroundColor};
    border-radius: 0 0 12px 12px;
    box-shadow: 10px 20px 40px 0 ${({ theme }) => theme.header.boxShadow};
    z-index: 1000;

    .rc-select-item-option {
      background: transparent;
      
      &:hover {
        background: transparent;
      }
    }
    
    .rc-virtual-list-holder {
      padding: 12px;
    }
    
    .rc-virtual-list-holder-inner {
      gap: 4px;
    }
    
    .rc-select-item-option {
      padding: 0;

      .lang-select__item {
        padding: 8px;
        font-size: 14px;
        background: ${({ theme }) => theme.header.backgroundColor};
        
        &:hover {
          background: ${({ theme }) => theme.hoverColors.fourth};
          border-radius: 12px;
        }
      }

      .lang-select__pref {
        display: none;
      }
    }
  }

  @media screen and (max-width: 992px) {
    margin-left: 15px;
  }
`;