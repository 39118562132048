import styled from "styled-components";
import merchantSprite from "./../../assets/images/css-sprite/marchant-sprite.css";

export const StyledFooter = styled.div`
  padding: 60px 0;
  background-color: ${({ theme }) => theme.backgroundDark};
  @media screen and (max-width: 992px) {
    padding: 50px 0;
  }
`;

export const StyledFooterContent = styled.div`
  .footer-links {
    padding-top: 40px;
    display: grid;
    grid-template-columns: 222px repeat(4, 1fr);
    grid-gap: 30px;

    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      grid-template-areas: 'info document'
                           'address email'
                           'slogan slogan'
    ;

      .footer-slogan {
        grid-area: slogan;
      }

      .footer-info-links {
        grid-area: info;
      }

      .footer-document-links {
        grid-area: document;
      }

      .footer-address-links {
        grid-area: address;
      }

      .footer-social-links {
        grid-area: social;
        grid-template-columns: 1fr;
        grid-template-areas: 'email';

        &__email {
          grid-area: email;
        }

        &__social-list {
          grid-area: social-list;
        }
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      grid-template-areas: 'info document'
                           'address email'
                           'slogan slogan'
    }
    @media screen and (max-width: 480px) {
      grid-template-columns: 100%;
      grid-template-areas: 'social'
                           'info'
                           'document'
                           'address'
                           'email'
                           'slogan'
    ;
    }
  }
`;

export const StyledFooterContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .footer-item__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${({ theme }) => theme.white};
  }

  .footer-item__list {
    li {
      padding: 8px 0;
      color: ${({ theme }) => theme.neutralColors.third};

      a {
        padding: 8px 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;

        &:hover {
          color: ${({ theme }) => theme.defaultColor};
        }
      }
    }
  }

  .social-wrapper {
    padding: 40px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 30px);
    grid-gap: 20px;
    border-bottom: 2px solid #5D5D5D;

    @media screen and (max-width: 768px) {
      padding: 20px 0;
    }

    li {
      padding: 0;
      opacity: 1;

      a {
        display: block;
        transition: all .3s ease;
        font-size: 32px;

        & > span {
          color: #5D5D5D;
        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
`;

export const StyledFooterBlock = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 100%;
  grid-template-rows: max-content;
  @media screen and (max-width: 768px) {
    grid-template-rows: minmax(120px, max-content);
  }
  @media screen and (max-width: 480px) {
    grid-template-rows: max-content;
  }
`;

export const StyledMerchantsWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, max-content);
  align-items: center;
  color: ${({ theme }) => theme.neutralColors.third};
  font-size: 25px;

  .merchant-sprite-icon {
    display: inline-flex;
  }

  .merchant-sprite-icon:before {
    content: '';
    background-image: url(${merchantSprite});
    background-repeat: no-repeat;
  }

  .bg-visa_secure:before {
    background-position: -108px -56px;
    width: 26px;
    height: 26px;
  }

  .bg-mastercard:before {
    background-position: -10px -56px;
    width: 33px;
    height: 26px;
  }

  .bg-mastercard_id_check:before {
    background-position: -108px -10px;
    width: 27px;
    height: 26px;
  }

  .bg-visa:before {
    background-position: -10px -10px;
    width: 78px;
    height: 26px;
  }

  @media screen and (max-width: 992px) {
    padding: 25px 0;
  }
`;

export const StyledDeveloperBy = styled.div`
  padding: 6px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 992px) {
    padding-bottom: 100px;
  }
  @media screen and (max-width: 576px) {
    padding-bottom: 80px;
  }

  & > a.link-wrapper {

    & > span {
      color: ${({ theme }) => theme.primaryColors.first};
      opacity: 0.75;
    }

    & > p {
      color: ${({ theme }) => theme.neutralColors.second};
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      padding-top: 5px;
    }
  }
`;

export const StyledFooterExploreBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 65px;

  @media screen and (max-width: 1200px) {
    gap: 40px;
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .explore-block {
    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__icon-wrapper {
      width: 40px;
      height: 40px;
      margin-bottom: 2px;
      font-size: 40px;
      color: ${({ theme }) => theme.primaryColors.first};
    }

    &__main-title {
      width: 558px;
      max-width: 100%;
      color: ${({ theme }) => theme.white};
      font-size: 52px;
      font-weight: 600;
      line-height: 1.36;

      @media screen and (max-width: 992px) {
        width: 100%;
        font-size: 40px;
      }
      @media screen and (max-width: 768px) {
        font-size: 32px;
      }
      @media screen and (max-width: 576px) {
        font-size: 24px;
      }
    }

    &__text {
      color: ${({ theme }) => theme.neutralColors.third};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .join-crypto {
    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${({ theme }) => theme.white};
    }

    &__btn {
      width: fit-content;
    }

    &__subtext {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: ${({ theme }) => theme.neutralColors.third};

      a {
        color: ${({ theme }) => theme.white};
      }
    }
  }
`;

export const StyledFooterSloganBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  .slogan {
    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__title {
      color: ${({ theme }) => theme.white};
      font-size: 24px;
      font-weight: 700;
      line-height: 1.38;
    }

    &__text {
      color: ${({ theme }) => theme.neutralColors.third};
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  .right-reserved {
    &__wrapper {

      & > .footer-author {
        margin-bottom: 10px;
        color: ${({ theme }) => theme.neutralColors.third};
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
`;