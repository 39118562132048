import React, { useEffect, useState } from "react";
import i18n from "i18next";
import Dropdown from "rc-dropdown";
import { NavLink, useLocation } from "react-router-dom";
import LanguageSelect from "../header/language/language-select";
import Toggle from "../theme-toggler/theme-toggler.component";
import InformationMenu from "./dropdown-items/nav-information-menu";
import NavItem from "./nav-item.component";
import { defaultLanguage } from "../../utils/consts.util";

import {
  StyledDropdownMenuItem,
  StyledHoverNavigation,
  StyledMenuItem,
  StyledMenuList,
  StyledNavigation
} from "./styled-navigation";

const Nav = ({ theme, toggleTheme, isLoggedIn, fixed, ready, t }) => {
  const location = useLocation();
  const language = i18n.language === defaultLanguage ? "/" : "/" + i18n.language + "/";

  const [hoverStyles, setHoverStyles] = useState({
    lineWrapper: { visibility: "hidden", opacity: 0, left: 20 },
    line: { visibility: "hidden", opacity: 0, width: 10 },
  });

  const hoverNavigation = (e, hover) => {
    const offsetLeft = e.target.offsetLeft;
    const offsetWidth = e.target.offsetWidth;

    setHoverStyles((prevState) => ({
      lineWrapper: {
        ...prevState.lineWrapper,
        visibility: hover ? "visible" : "hidden",
        opacity: hover ? 1 : 0,
        left: hover ? offsetLeft : prevState.lineWrapper.left,
      },
      line: {
        ...prevState.line,
        visibility: hover ? "visible" : "hidden",
        opacity: hover ? 1 : 0,
        width: hover ? offsetWidth : prevState.line.width,
      },
    }));
  };

  const hoverHandler = (isHovered) => (e) => {
    e.persist();
    hoverNavigation(e, isHovered);
  };

  useEffect(() => {
    setHoverStyles({
      lineWrapper: { visibility: "hidden", opacity: 0, left: 20 },
      line: { visibility: "hidden", opacity: 0, width: 10 },
    });
  }, [location]);

  if (!ready) {
    return null;
  }

  return (
    <StyledNavigation id="nav">
      {ready &&
        <React.Fragment>
          <StyledMenuList>
            <StyledMenuItem
              className="menu-item"
              onMouseOver={hoverHandler(true)}
              onMouseOut={hoverHandler(false)}
            >
              <NavLink
                exact
                to={{
                  pathname: `${language}`,
                  state: { scrollCalc: true }
                }}
                className={`menu-link`}
                activeClassName="menu-link_current"
              >
                <div className="menu-link__icon">
                  <span className="wm-icon-coin-selection" />
                </div>
                <div className="menu-link__title">
                  {t("exchange")}
                </div>
              </NavLink>
            </StyledMenuItem>
            <NavItem
              to="/rates"
              icon="cryptocurrency-courses"
              linkTitle={t("rates")}
              dataTestId="rates"
              onMouseOver={hoverHandler(true)}
              onMouseOut={hoverHandler(false)}
            />
            <NavItem
              to="/news"
              icon="newsletter"
              linkTitle={t("news")}
              className="blog-link"
              dataTestId="news"
              onMouseOver={hoverHandler(true)}
              onMouseOut={hoverHandler(false)}
            />
            <Dropdown
              getPopupContainer={() => document.getElementById("nav")}
              overlay={InformationMenu}
              trigger={["click"]}
              placement="bottomLeft"
              className="information-link"
              animation="slide-up"
            >
              <StyledDropdownMenuItem
                data-testid="information-desktop"
                className="menu-item"
                onClick={(event) => event.preventDefault()}
                onMouseOver={hoverHandler(true)}
                onMouseOut={hoverHandler(false)}
              >
                {t("information.title")}
              </StyledDropdownMenuItem>
            </Dropdown>
            {/* mobile menu item start*/}
            <NavItem
              to="/info"
              icon="info"
              linkTitle={t("information.title")}
              className="information-mobile"
              dataTestId="information-mobile"
              onMouseOver={hoverHandler(true)}
              onMouseOut={hoverHandler(false)}
            />
            {/* mobile menu item end*/}
            <NavItem
              to="/contacts"
              icon="contacts"
              linkTitle={t("contacts")}
              dataTestId="contacts"
              className="contacts-link"
              onMouseOver={hoverHandler(true)}
              onMouseOut={hoverHandler(false)}
            />
            <StyledHoverNavigation style={hoverStyles.lineWrapper}>
              <div style={hoverStyles.line} />
            </StyledHoverNavigation>
          </StyledMenuList>
          <LanguageSelect />
          <Toggle
            theme={theme}
            toggleTheme={toggleTheme}
          />
        </React.Fragment>
      }
    </StyledNavigation>
  );
};

export default Nav;