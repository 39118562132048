import i18n from "i18next";
import React from "react";
import { NavLink } from "react-router-dom";
import { defaultLanguage } from "../../utils/consts.util";

const CustomLink = ({ children, to, className, activeClassName, onClick, target, title, exact, dataTestId = null }) => {
  const language = i18n.language === defaultLanguage ? "" : "/" + i18n.language;

  return (
    <NavLink
      to={typeof to !== 'object' ? `${language}${to}`: to}
      className={className}
      activeClassName={activeClassName}
      onClick={onClick}
      target={target}
      title={title}
      exact={exact}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};

export default CustomLink;