import styled from "styled-components";

export const StyledShowSidebar = styled.div`
  cursor: pointer;

  .sidebar-btn__icon {
    font-size: ${({ fixed }) => fixed ? "32px" : "38px"};
    line-height: 0;
    color: ${({ theme }) => theme.defaultColor};
    transition: font-size .3s;

    span {
      background-color: ${({ theme }) => theme.header.profileBackground};
      border-radius: 10px;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.defaultColor};
  }

  @media screen and (max-width: 992px) {
    .sidebar-btn__icon {
      font-size: 38px;
    }
  }
`;

export const StyledSidebar = styled.div`
  height: 100%;
`;

export const StyledAccount = styled.div`
  margin-bottom: 12px;
  padding-bottom: 16px;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(242, 242, 242, 0.2);
  background-color: ${({ theme }) => theme.header.backgroundColor};

  .user {
    width: 100%;
    min-width: 0;

    &__name {
      font-size: 24px;
      font-weight: 700;
      color: ${({ theme }) => theme.defaultColor};
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__email {
      font-size: 14px;
      color: ${({ theme }) => theme.header.text};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .sidebar-account__settings {
    padding: 8px 0;

    .settings-link {
      height: 25px;
      font-size: 24px;
      color: ${({ theme }) => theme.defaultColor};
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:hover {
        transform: rotate(180deg);
        transition: all .3s ease;
      }
    }
  }
`;

export const StyledSidebarItem = styled.div`
  padding: 4px 0;

  .sidebar-link {
    padding: 8px 12px;
    color: ${({ theme }) => theme.header.text};

    &__icon {
      width: 24px;
      font-size: 24px;
      line-height: 0;
      text-align: center;
    }

    &__title {
      padding-left: 12px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.hoverColors.fourth};

      .sidebar-link__icon {
        color: ${({ theme }) => theme.defaultColor};
      }
    }

    &_current {
      background-color: ${({ theme }) => theme.hoverColors.fourth};
      font-weight: 500;

      .sidebar-link__icon {
        color: ${({ theme }) => theme.defaultColor};
      }
    }
  }

  .document-verification {
    .sidebar-link__title {
      max-width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const SidebarLink = styled.button`
  width: 100%;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 8px;
  outline: none;
`;

export const StyledLoadingSidebar = styled.div`
  .loading-sidebar {
    position: relative;
    ${({ type }) => type === "error" ? `
        color: #E82020;
        text-decoration: line-through` : null};

    &:hover {
      opacity: 0.55;
      cursor: wait;
    }
  }

  .loading-sidebar__icon {
    width: 21px;
    height: 24px;
    padding-top: 2px;
    display: inline-flex;

    & > div {
      margin: 0;
    }

    @media screen and (max-width: 992px) {
      position: static;
    }
  }
`;

export const StyledItemWithBadge = styled.div`
  position: relative;
`;

export const StyledDocumentVerification = styled.div`
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-family: 'theme-icon', serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  background-color: #faad14;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: auto;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-10px);
`;