import React from "react";
import { MoonLoader } from "react-spinners";
import { css } from "@emotion/core";

const Spinner = ({ size, color = "#1885F2", display, margin }) => {

  const override = css`
    display: ${display};
    margin: ${margin ? margin : "15px auto"};
  `;

  return <MoonLoader css={override} size={size} color={color} loading={true} />;
};

export default Spinner;