import React from "react";
import { StyledFooterContentItem } from "./styled-footer";

const FooterEmailLink = ({ t, ready }) => {

  return (
    <>
      <StyledFooterContentItem className="footer-item footer-social-links__email">
        <div className="footer-item__title">
          {ready && t("linkEmailTitle")}
        </div>
        {
          ready &&
          <ul className="footer-item__list">
            <li>
              <a href="mailto:info@whitemoney.pl">
                info@whitemoney.pl
              </a>
            </li>
          </ul>
        }
      </StyledFooterContentItem>
    </>
  );
};

export default FooterEmailLink;