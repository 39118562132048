import styled from "styled-components";

export const StyledCookieMessageWrapper = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.neutralColors.fourth};
  border-top: 1px solid ${({theme}) => theme.defaultColor};
  padding: 15px 0;
  position: fixed;
  bottom: 0;
  z-index: 99999;
  animation: cookieLoad 1s ease;

  @keyframes cookieLoad {
    0% {
      opacity: 0.5;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const StyledCookieMessageContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  grid-gap: 15px;
  h4 {
    color: ${({theme}) => theme.defaultColor};
    font-size: 16px;
  }
  p {
    padding: 10px 0;
    text-align: justify;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;
    text-align: center;
    p {
      text-align: center;
    }
  }
`;

export const StyledCookieMessageContent = styled.div``;

export const StyledCookieMessageAction = styled.div`
  display: grid;
  grid-gap: 15px;
  button {
    letter-spacing: normal;
  }
`;

export const StyledCookiePreferenceWrapper = styled.div``;

export const StyledCookiePreference = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid ${({theme}) => theme.borderElements};
  .preference-item {
    padding-bottom: 15px;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 15px;
    h5 {
      padding-bottom: 5px;
      color: ${({theme}) => theme.defaultColor};
      font-size: 16px;
    }
  }

  .readOnly {
    opacity: 0.5;
  }
`;