import React from "react";
import { useLocation } from "react-router-dom";
import CustomLink from "../customLink/CustomLink";

import { StyledMenuItem } from "./styled-navigation";

const NavItem = ({ to, exact, icon, linkTitle, className, onMouseOver, onMouseOut, dataTestId = null }) => {
  const { pathname } = useLocation();
  const isActive = pathname.includes(to);

  return (
    <StyledMenuItem className={`menu-item ${className}`} onMouseOver={!isActive ? onMouseOver : null} onMouseOut={!isActive ? onMouseOut : null}>
      <CustomLink to={to} className="menu-link" activeClassName="menu-link_current" exact={exact} dataTestId={dataTestId}>
        <div className="menu-link__icon">
          <span className={`wm-icon-${icon}`} />
        </div>
        <div className="menu-link__title">
          {linkTitle}
        </div>
      </CustomLink>
    </StyledMenuItem>
  );
};

export default NavItem;