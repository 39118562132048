import React from "react";
import CustomLink from "../customLink/CustomLink";
import { useTranslation } from "react-i18next";

import WhitemoneyLogo from "../../assets/images/logo.svg";

import {
  SiteDescription,
  SiteName,
  SiteTitle,
  StyledLogo,
  StyledLogoIcon,
  StyledLogoIconWrapper,
  StyledLogoShadow
} from "./styled-logo";


const Logo = ({ fixed, ready, t }) => {

  return (
    <CustomLink to="/" className="logo">
      <StyledLogo fixed={fixed}>
        {/*<StyledLogoShadow className="logo-shadow">*/}
          <StyledLogoIconWrapper>
            <StyledLogoIcon className="logo-icon">
              <img alt="whitemoney-logo" src={WhitemoneyLogo} width="55" height="61" />
            </StyledLogoIcon>
          </StyledLogoIconWrapper>
        {/*</StyledLogoShadow>*/}
        {/*<SiteTitle className="site-title">*/}
        {/*  <SiteName>*/}
        {/*    White Money*/}
        {/*  </SiteName>*/}
        {/*  /!*<SiteDescription>*!/*/}
        {/*  /!*  {ready && t("logo.subText")}*!/*/}
        {/*  /!*</SiteDescription>*!/*/}
        {/*</SiteTitle>*/}
      </StyledLogo>
    </CustomLink>
  );
};

export default Logo;