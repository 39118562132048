import React from 'react';
import { StyledLoadingSidebar, StyledShowSidebar } from '../styled-sidebar';
import Spinner from '../../spinner/spinner.component';

const SidebarButtonSkeleton = ({text, type}) => {
  return (
    <StyledLoadingSidebar type={type} data-testid="profile-preloader">
      <StyledShowSidebar className="sidebar-btn loading-sidebar">
        <div className="sidebar-btn__icon loading-sidebar__icon">
          {/*<span className="icon-bar"/>*/}
          <Spinner size="18px"/>
        </div>
      </StyledShowSidebar>
    </StyledLoadingSidebar>
  );
};

export default SidebarButtonSkeleton;