import React, { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Title from "../../components/title/title.component";
import AlertMessage from "../../components/alert/alert.component";
import OldRequisitionItem from "../../components/old-requisition-list/old-requisition-item.component";
import PageSpinner from "../../components/spinner/page-spinner.component";
import Can from "../../components/can/can.component";
import { closableNotificationWithClick } from "../notification/closable-notification-with-click.component";
import { authenticationConfig } from "../../utils/authenticationConfig";
import { requisition } from "../../rbac-consts";
import { GET_USER_RBAC_DETAILS } from "../../graphql/queries/user.query";

import { StyledContainer } from "../styles/styled-container";
import { StyledRequisitionWrapper } from "../requisition-list/styled-requisition";
import {
  StyledColHead,
  StyledScrollTable,
  StyledTable,
  StyledTableBody,
  StyledTableHeader
} from "../styles/styled-table";


const OldRequisitionContainer = () => {

  const client = useApolloClient();

  const { userRole } = client.readQuery({
    query: GET_USER_RBAC_DETAILS
  });

  const [oldRequisitions, setOldRequisitions] = useState(null);

  const fetchOldRequisitions = () => {
    axios.get("/api/panel/old-requisitions", authenticationConfig()).then(response => {
      if (response.status === 200) {
        setOldRequisitions(response.data["hydra:member"]);
      }
    }).catch(error => {
      closableNotificationWithClick(error.response.data.message, "error");
    });
  };

  useEffect(() => {
    fetchOldRequisitions();
  }, []);

  if (!oldRequisitions) {
    return <PageSpinner />;
  }

  if (oldRequisitions.length === 0) {
    return (
      <StyledContainer>
        <Helmet>
          <title>Архивированные заявки - White Money</title>
        </Helmet>
        <AlertMessage margin="15px 0 0" type="warning" message="Заявки отсутствуют" />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer size={userRole !== "client" ? "xl" : null}>
      <Helmet>
        <title>Архивированные заявки - White Money</title>
      </Helmet>
      <StyledRequisitionWrapper role={userRole}>
        <Title style={{ marginBottom: 0 }} as="h1" title="Архивированные обмены" subTitle="Заявки" />
        <StyledScrollTable>
          <StyledTable width="1540" className="requisition-table">
            <StyledTableHeader
              scroll="auto"
              col={userRole !== "client" ? "9" : "6"}
              className="requisition-table__header"
            >
              <StyledColHead>Номер</StyledColHead>
              <StyledColHead>Дата создания</StyledColHead>
              <Can
                role={userRole}
                perform={requisition.CLIENT_DETAILS}
                yes={() => <StyledColHead>Дата выполнения</StyledColHead>}
              />
              <Can
                role={userRole}
                perform={requisition.DATE_INFO}
                yes={() => (
                  <StyledColHead className="requisition-table__client-head">
                    Клиент
                  </StyledColHead>
                )}
              />
              <StyledColHead className="requisition-table__payment-system-head">
                Платежная система
              </StyledColHead>
              <StyledColHead>Сумма платежа</StyledColHead>
              <StyledColHead>Сумма к получению</StyledColHead>
              <Can
                role={userRole}
                perform={requisition.USER_INFO}
                yes={() => (
                  <StyledColHead className="requisition-table__manager-head">
                    Менеджер
                  </StyledColHead>
                )}
              />
              <StyledColHead>Статус</StyledColHead>
              <StyledColHead />
            </StyledTableHeader>
            <StyledTableBody>
              {oldRequisitions && oldRequisitions.map((oldRequisition, key) => (
                <OldRequisitionItem key={key} oldRequisition={oldRequisition} />
              ))}
            </StyledTableBody>
          </StyledTable>
        </StyledScrollTable>
      </StyledRequisitionWrapper>
    </StyledContainer>
  );
};

export default OldRequisitionContainer;