import axios from "axios";
import React, { useEffect, useState } from "react";
import { authenticationConfig } from "../../utils/authenticationConfig";
import { creditCardStatuses, responseStatus } from "../../utils/consts.util";
import { pusherChannels, pusherEvents } from "../../utils/pusher";
import { PusherService } from "../../utils/socketPusherService";
import { closableNotificationWithClick } from "../notification/closable-notification-with-click.component";
import { StyledBadgeNotification } from "./styled-badge-notification";

const BadgeNotificationClientIban = () => {
  const [count, setCount] = useState("");

  const fetchClientIbanCount = () => {
    axios.get("/api/client_ibans?status=" + creditCardStatuses.NOT_VERIFIED + "&itemsPerPage=5000",
      authenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setCount(response.data["hydra:totalItems"]);
      }
    }).catch(error => {
      closableNotificationWithClick(error.data, "error");
    });
  };

  useEffect(() => {
    fetchClientIbanCount();

    let channel = pusherChannels.CLIENT_IBAN.CLIENT_IBAN_BADGE;

    PusherService.connectToSocket(
      channel,
      pusherEvents.CLIENT_IBAN.CREATE,
      fetchClientIbanCount
    );

    PusherService.connectToSocket(
      channel,
      pusherEvents.CLIENT_IBAN.UPDATE,
      fetchClientIbanCount
    );

    PusherService.connectToSocket(
      channel,
      pusherEvents.CLIENT_IBAN.DELETE,
      fetchClientIbanCount
    );

    return () => {
      PusherService.unsubscribeFromChannel(channel);
    };
  }, []);

  if (count <= 0){
    return null;
  }

  return (
    <StyledBadgeNotification data-testid="sidebar-notification-iban">
      {count}
    </StyledBadgeNotification>
  );
};
export default BadgeNotificationClientIban;
