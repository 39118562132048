import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTopProvider ({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen((e) => {
      if (e.state) {
        if (e.state?.scrollToTop && e.pathname !== e.state?.pathNameTest) {
          window.scrollTo(0, 0);
        }

        return;
      }

      if (!history.location.search) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      {children}
    </>
  );
} 

export default withRouter(ScrollToTopProvider);