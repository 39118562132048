import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import CookieMessage from "../cookie-message/cookie-message.component";
import FooterDocumentLinks from "./footer-document-links";
import FooterAddressLinks from "./footer-address-links";
import FooterSocialLinks from "./footer-social-links";
import FooterDescription from "./footer.description";
import FooterEmailLink from "./footer.email.link";
import FooterInfoLinks from "./footer-info-links";
import FooterDeveloped from "./footer.developed";
import FooterSlogan from "./footer.slogan";

import { StyledContainer } from "../styles/styled-container";
import {
  StyledFooter,
  StyledFooterContent
} from "./styled-footer";
import { AppContext } from "../../App";

const Footer = ({ theme }) => {
  const initialCookies = {
    strictlyNecessary: true,
    functional: false,
    performance: false,
    targeting: false,
    agree: false
  };
  const [cookie, setCookie] = useState(initialCookies);

  const { authenticated } = useContext(AppContext);

  const { t, ready } = useTranslation("footer", { useSuspense: false });

  return (
    <footer>
      <StyledFooter>
        {!Cookies.get("useCookiesConsent") && <CookieMessage cookie={cookie} setCookie={setCookie} />}
        <StyledContainer wrapper="content">
          <StyledFooterContent>
            <FooterDescription t={t} ready={ready} authenticated={authenticated} />
            <FooterSocialLinks />
            <div className="footer-links">
              <FooterSlogan t={t} ready={ready} />
              <FooterInfoLinks />
              <FooterDocumentLinks />
              <FooterAddressLinks />
              <FooterEmailLink t={t} ready={ready} />
            </div>
          </StyledFooterContent>
        </StyledContainer>
      </StyledFooter>
      <FooterDeveloped theme={theme} />
    </footer>
  );
};

export default Footer;