import React from "react";

import { StyledSubTitle, StyledTitle, StyledTitleDescription, StyledTitleWrapper } from "./styled-title";

const Title = ({ as, className, title, subTitle, description, align, fullSize, mb, weight, style }) => {
  return (
    <StyledTitleWrapper className={className} align={align} fullSize={fullSize} mb={mb} style={style}>
      {subTitle &&
        <StyledSubTitle>
          {subTitle}
        </StyledSubTitle>
      }
      <StyledTitle as={as} weight={weight}>
        {title}
      </StyledTitle>
      {description &&
        <StyledTitleDescription>
          {description}
        </StyledTitleDescription>
      }
    </StyledTitleWrapper>
  );
};
export default Title;