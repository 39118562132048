import styled from "styled-components";

export const StyledLogo = styled.div`
  display: inline-flex;

  .site-title {
    text-align: start;
  }

  &:hover {
    .site-title {
      transform: scale(1.02);
    }
  }

  ${({ fixed }) => fixed && `
    .logo-icon {
      width: 40px; 
      &:before {
        transform: scale(0.7);
        top: 1px;
        left: -11px;
      }
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
    .logo-shadow:after {
      width: 20px;
      height: 20px;
      @media screen and (max-width: 992px) {
        width: 35px;
        height: 35px;
      }
    }
    .site-title {
      // visibility: hidden;
      // opacity: 0;
      // display: none;
    }
    &:hover {
      .logo-icon {
        &:before {
          animation: christmas-anim-fixed 1s ease forwards;
        }
      }
    };
  `};
`;

export const StyledLogoShadow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
  position: relative;

  &:after {
    content: '';
    width: 35px;
    height: 35px;
    border-radius: 20px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 8px;
    transition: all .3s ease;
  }
`;

export const StyledLogoIconWrapper = styled.div`
  width: 55px;
  height: 61px;
  z-index: 1;
`;

export const StyledLogoIcon = styled.div`
  width: 55px;
  height: 61px;
  z-index: 1;
  transition: all .3s ease;

  img {
    width: 100%;
  }
`;

export const SiteTitle = styled.div`
  //margin-left: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const SiteName = styled.h3`
  font-size: 24px;
  font-weight: 700;
  //letter-spacing: 3px;
  line-height: 35px;
  //text-transform: uppercase;
`;

export const SiteDescription = styled.p`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.2px;
`;