import { gql } from "apollo-boost";

const GET_CLIENT_ACCOUNT_DETAILS = gql`
  query accountDetailsClient($id: ID!) {
    accountDetailsClient(id: $id) {
      id
      firstname
      lastname
      email
      isTwoFaEnabled
      isMailSubscriber
      isTechMailSubscriber
      isDeleted
      isVerified
      mailingLanguage
      mediaObject {
        id
        base64
      }
      verificationInfo
    }
  }
`;

const GET_ADMIN_ACCOUNT_DETAILS = gql`
  query accountDetailsAdmin($id: ID!) {
    admin(id: $id) {
      id
      firstname
      lastname
      email
      isEnabled
      createdAt
      isMailSubscriber
      isTechMailSubscriber
      mailingLanguage
      mediaObject {
        id
        base64
      }
    }
  }
`;

const GET_MANAGER_ACCOUNT_DETAILS = gql`
  query accountDetailsManager($id: ID!) {
    manager(id: $id) {
      id
      firstname
      lastname
      email
      isMailSubscriber
      isTechMailSubscriber
      isBank
      mailingLanguage
      mediaObject {
        id
        base64
      }
    }
  }
`;

const GET_SEO_ACCOUNT_DETAILS = gql`
    query accountDetailsSeo($id: ID!) {
        seo(id: $id) {
            id
            firstname
            lastname
            email
            isMailSubscriber
            isTechMailSubscriber
            createdAt
            mailingLanguage
            mediaObject {
                id
                base64
            }
        }
    }
`;

const GET_SUPPORT_ACCOUNT_DETAILS = gql`
    query accountDetailsSupport($id: ID!) {
        support(id: $id) {
            id
            firstname
            lastname
            email
            isMailSubscriber
            isTechMailSubscriber
            createdAt
            mailingLanguage
            mediaObject {
                id
                base64
            }
        }
    }
`;

const UPDATE_CLIENT_DETAILS = gql`
  mutation setUserDetailsMutationClient(
    $firstname: String!
    $lastname: String!
  ) {
    setUserDetailsMutationClient(
      input: { firstname: $firstname, lastname: $lastname }
    ) {
      client {
        id
      }
    }
  }
`;

export {
  GET_ADMIN_ACCOUNT_DETAILS,
  GET_MANAGER_ACCOUNT_DETAILS,
  GET_SEO_ACCOUNT_DETAILS,
  GET_CLIENT_ACCOUNT_DETAILS,
  UPDATE_CLIENT_DETAILS,
  GET_SUPPORT_ACCOUNT_DETAILS
};
