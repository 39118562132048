import React from "react";
import { NavLink } from "react-router-dom";
import NavInfoMenuItem from "./nav-information-menu-item";
import { useTranslation } from "react-i18next";

import { StyledInfoMenuList, StyledInfoMenuWrapper } from "./styled-dropdown-nav";

const AboutMenu = () => {
  const { t } = useTranslation("navigations");

  return (
    <StyledInfoMenuWrapper className="info-menu">
      {/*<div className="info-menu__title">*/}
      {/*  {t("information.subTitle1")}*/}
      {/*</div>*/}
      <StyledInfoMenuList>
        <NavInfoMenuItem
          to="/about-us"
          icon="about-us"
          linkTitle={t("information.aboutUs")}
        />
        {/*<NavInfoMenuItem*/}
        {/*  to="/news"*/}
        {/*  icon="blog"*/}
        {/*  linkTitle={t("information.news")}*/}
        {/*  rel="canonical"*/}
        {/*  // target="_blank"*/}
        {/*/>*/}
        {/*<NavInfoMenuItem*/}
        {/*  to="/cities"*/}
        {/*  icon="menu-city"*/}
        {/*  linkTitle={t("information.cities")}*/}
        {/*  rel="canonical"*/}
        {/*/>*/}
        <NavInfoMenuItem
          to="/reviews"
          icon="reviews"
          linkTitle={t("information.reviews")}
        />
        <NavInfoMenuItem
          to="/partners"
          icon="referrals"
          linkTitle={t("information.partners")}
        />
        <NavInfoMenuItem
          to="/network-confirmations"
          icon="number-confirmed"
          linkTitle={t("information.networkConfirmations")}
        />
      </StyledInfoMenuList>
    </StyledInfoMenuWrapper>
  );
};

export default AboutMenu;