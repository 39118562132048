import moment from "moment";
import { dateFilter } from "./consts.util";
import { DateTime } from "luxon";
import { getSynchronizedTime } from "./serverTime";

const convertDateToTimestamp = (date) => {
  return date ? (Date.parse(date.split('-').reverse().join('-')) / 1000).toString() : "";
};

const convertDateToTimestampStart = (date) => {
  return date ? (new Date(date.split('-').reverse().join('-')).setHours(0, 0, 0) / 1000).toString() : "";
};
const convertDateToTimestampEnd = (date) => {
  return date ? (new Date(date.split('-').reverse().join('-')).setHours(23, 59, 59) / 1000).toString() : "";
};

const convertDateFromDatePickerToTimestamp = (date) => {
  return Date.parse(date)/1000;
};

const TimestampToDateNew = (date) => {
  return date ? moment(date).format('DD.MM.YYYY') : "";
};

const TimestampToDate = (date) => {
  return date ? new Date(date / 1000) : "";
};

const DateToTimestamp = (date) => {
  return date ? (Date.parse(date) / 1000).toString() : "";
};

const convertDateToLocalDateTime = (date) => {
  return new Date(Date.parse(date.split('-').reverse().join('-')));
};

const convertDateEndToLocalDateTime = (date) => {
  let dayEndDate = new Date(Date.parse(date.split('-').reverse().join('-')));

  dayEndDate.setHours(23, 59, 59);

  return dayEndDate;
};

const convertToDOBNewFormat = (date, format = "dd.MM.yyyy") => {
  return DateTime.fromJSDate(new Date(date)).toFormat(format)
}

const MillisecondsToTimestamp = (date, endOfDay = false) => {
  if (date && endOfDay) {
    date.setHours(23, 59, 59, 999);
  }

  return date ? (Date.parse(date)).toString() : "";
};

const convertDateStringToDate = (dateString) => {
  if (!dateString || !dateString.length) {
    return "";
  }

  const parsedDate = DateTime.fromFormat(dateString, 'dd.MM.yyyy');
  return parsedDate.toJSDate();
};

const convertLocalDateTimeToFormat = (date, format) => {
  if (date != null && date.getFullYear() > dateFilter.MAX_YEAR) {
    date.setFullYear(getSynchronizedTime().getFullYear());
  }
  return date ? moment(date).format(format) : "";
};

const startCurrentDate = (format) => {
  return moment(new Date(getSynchronizedTime().getFullYear(), new Date().getMonth(), 1)).format(format);
};

const endCurrentDate = (format) => {
  return moment(new Date(getSynchronizedTime().getFullYear(), new Date().getMonth() + 1, 0)).format(format);
};

const convertToDOBFormat = (date) => {
  return date ? moment(date).format("YYYY-MM-DD") : "";
}

const DateToTimestampStart = (date) => {
  return date ? (new Date(date).setHours(0, 0, 0) / 1000) : "";
};

const DateToTimestampWithMilliseconds = (date) => {
  return Date.parse(date);
};

const DateToTimestampEnd = (date) => {
  return date ? (new Date(date).setHours(23, 59, 59) / 1000) : "";
};

const TimestampSecondToDate = (date) => {
  return date ? new Date(date * 1000) : "";
};

const TimestampToDateTime = (timestamp) => {
  if (!timestamp) {
    return "-";
  }

  return DateTime.fromMillis(timestamp * 1000).toFormat("dd.MM.yyyy, HH:mm");
};

export {
  convertDateToTimestamp,
  convertDateToTimestampStart,
  convertDateToTimestampEnd,
  convertDateToLocalDateTime,
  convertDateEndToLocalDateTime,
  convertLocalDateTimeToFormat,
  convertDateFromDatePickerToTimestamp,
  startCurrentDate,
  endCurrentDate,
  MillisecondsToTimestamp,
  TimestampToDate,
  DateToTimestamp,
  convertToDOBFormat,
  DateToTimestampStart,
  DateToTimestampEnd,
  TimestampSecondToDate,
  TimestampToDateTime,
  convertToDOBNewFormat,
  convertDateStringToDate,
  DateToTimestampWithMilliseconds
};
