import styled from "styled-components";
import googleSprite from "./../../assets/images/css-sprite/google-sprite.css";

export const StyledLoginWrapper = styled.div`
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
  .loading-login-form {
    max-width: 450px;
    width: 100%;
    margin: 0 auto 20px;
  }

  .login-form {
    text-align: center;

    &__footer {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 350px) {
        flex-direction: column;
        gap: 10px;

        & > button, a {
          width: 100%;
        }
      }
    }

    &__google {
      margin: 16px auto 0;
      padding: 2px 24px;

      &:before {
        content: '';
        background-image: url(${googleSprite});
        background-repeat: no-repeat;
      }

    }

    &__user-agree {
      margin-top: -8px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
    }

    &__button {
      margin-right: 16px;

      &:last-child {
        margin: 0;
      }
    }

    &__forgot-password {
      padding-top: 16px;
      color: ${({ theme }) => theme.neutralColors.second};
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.36;
    }
  }

  .two-factor-form {
    &__title {
      padding-bottom: 25px;
    }

    &__input input {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 5px;
      text-align: center;
    }

    &__footer {
      padding: 10px 0;
      display: flex;
      justify-content: center;
    }
  }
`;

export const StyledLoginContainerWrapper = styled.div`
  overflow: hidden;
`;