import React from "react";
import { getSynchronizedTime } from "../../utils/serverTime";
import { StyledFooterSloganBlock } from "./styled-footer";
import FooterMerchants from "./footer-merchants";

const FooterSlogan = ({ t, ready }) => {
  const currentYear = (getSynchronizedTime().getFullYear());

  return (
    <StyledFooterSloganBlock className="footer-slogan">
      <div className="slogan__wrapper">
        <p className="slogan__title">White Money</p>
        <p className="slogan__text">{t("sloganText")}</p>
      </div>
      <div className="right-reserved__wrapper">
        <div className="footer-author">
          {ready && `© ${currentYear > 2023 ? `2023 — ${currentYear}` : currentYear} ${t("rights")}`}
        </div>
        <FooterMerchants />
      </div>
    </StyledFooterSloganBlock>
  );
};

export default FooterSlogan;