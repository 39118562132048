import styled from "styled-components";

const colorButton = {
  color: String
};

const styledSize = (size) => {
  switch (size) {
    case "small":
      return `
        padding: 8px 24px;
      `;
    case "large":
      return `
        padding: 12px 32px;
      `;
    default:
      return ``;
  }
};

const styleButton = (theme, color, border) => {

  switch (color) {
    case "main":
      return `
        padding: 12px 32px;
        color: #fff;
        background: ${theme.defaultColor};
        
        &:hover {
          background-color: ${theme.hoverColors.first};
        }
        
        @media screen and (max-width: 576px) {
          padding: 10px 28px;
        }
    `;
    case "secondary":
      return `
      color: ${theme.primaryColors.first};
      background-color: transparent;
      border: ${border === "none" ? "none" : `1px solid ${theme.primaryColors.first}`};
      &:hover {
       color: ${theme.primaryColors.first};
       background-color: ${theme.hoverColors.second};
      }
      @media screen and (max-width: 992px) {
        border: 1px solid ${theme.primaryColors.first};
      }
      `;
    case "success":
      return `
        color: ${border ? theme.successColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.successColors.first};
        border: ${border === "none" ? "none" : `1px solid ${theme.successColors.first}`};
        &:hover {
          background-color: ${border ? theme.successColors.third : theme.successColors.second};
        }
    `;
    case "danger":
      return `
        color: ${border ? theme.dangerColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.dangerColors.first};
        border: ${border === "none" ? "none" : `1px solid ${theme.dangerColors.first}`};
        &:hover {
          background-color: ${border ? theme.dangerColors.third : theme.dangerColors.second};
        }
        @media screen and (max-width: 992px) {
          border: 1px solid ${theme.dangerColors.first};
        }
    `;
    case "warning":
      return `
        color: ${border ? theme.warningColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.warningColors.first};
        border: ${border === "none" ? "none" : `1px solid ${theme.warningColors.first}`};
         &:hover {
          background-color: ${border ? theme.warningColors.third : theme.warningColors.second};
        }
    `;
    case "info":
      return `
        color: ${border ? theme.infoColors.first : "#fff"};
        background-color: ${border ? "transparent" : theme.primaryColors.first};
        border: ${border === "none" ? "none" : `1px solid ${theme.primaryColors.first}`};
         &:hover {
          background-color: ${border ? theme.infoColors.third : theme.infoColors.second};
        }
        @media screen and (max-width: 992px) {
          border: 1px solid ${theme.primaryColors.first};
        }
    `;
    default:
      return `
        color: ${theme.neutralColors.second};
        background: transparent;
        border: 1px solid #B7BFC9;
        &:hover {
          color: ${theme.neutralColors.third};
        }
    `;
  }
};

export const StyledButton = styled("button", colorButton)`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  width: ${({ figure }) => figure !== "circle" ? "auto" : "46px"};
  height: ${({ figure }) => figure !== "circle" ? "auto" : "46px"};
  padding: ${({ figure }) => figure !== "circle" ? "10px 14px" : "0"};
  color: ${({ theme }) => theme.text};
  font-size: 16px;
  font-weight: ${({ weight }) => weight === "bold" ? "700" : "600"};
  text-align: center;
  vertical-align: middle;
  text-transform: ${({ weight }) => weight === "bold" ? "uppercase" : "inherit"};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: ${({ figure }) => figure === "circle" ? "50%" : "12px"};
  outline: none;
  cursor: pointer;
  transition: all .1s ease;
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    transform: scale(1) !important;
  `};

  ${({ theme, color, border }) => styleButton(theme, color, border)}
  ${({ size }) => styledSize(size)}
  &:hover {
    transform: scale(0.98);
  }

  &:active {
    transform: scale(0.95);
  }
`;
