import React, { useContext, useEffect, useMemo } from "react";
import { Route, useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";
import nprogress from "nprogress";

import { AppContext } from "../../App";
import IS_LOGGED_IN from "../../graphql/queries/login.query";
import { PusherService } from "../../utils/socketPusherService";

const LoadRouteComponent = (props) => {

  const history = useHistory();
  const client = useApolloClient();

  const { setAuthenticated } = useContext(AppContext);

  const logOut = () => {

    if (!localStorage.getItem("token")) {
      setAuthenticated(false);
      client.writeQuery({
        query: IS_LOGGED_IN,
        data: { isLoggedIn: false }
      });

      localStorage.removeItem("token");
      localStorage.removeItem("country");

      PusherService.unsubscribeFromAllChannels();

      if (!window.location.href.includes("/panel")) {
        return;
      }

      history.push("/login");
    }
  };

  const logIn = () => {
    window.location.reload();
  }

  useMemo(() => {
    nprogress.start();
  }, []);

  useEffect(() => {
    nprogress.done();

    if (!window.location.pathname.includes("panel") && !window.location.pathname.includes("login")) {
      sessionStorage.removeItem("link");
    }

    /*if (!window.location.pathname.includes("panel")) {
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "ewu50m5j4z");
    }*/

    window.addEventListener("storage", function (event) {
      if (event.key === "token") {
        if (!event.newValue) {
          logOut();

          return;
        }
        if (!event.oldValue) {
          logIn();
        }
      }
    });
  }, []);

  return <Route {...props} />;
};

export default React.memo(LoadRouteComponent);
