import { DateToTimestampWithMilliseconds } from "./datetime.util";
import { getSynchronizedTime } from "./serverTime";

export const getExpirationDate = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(Buffer.from(jwtToken.split(".")[1], 'base64').toString('utf-8'));

  return jwt && jwt.exp && jwt.exp * 1000 || null;
};

export const isExpired = (exp) => {
  if (!exp) {
    return false;
  }
  return DateToTimestampWithMilliseconds(getSynchronizedTime()) >= exp;
};
