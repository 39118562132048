import styled from "styled-components";

export const StyledBackToTop = styled.div`
  width: 60px;
  height: 60px;
  background: ${({ theme }) => theme.neutralColors.fifth};
  border: 1px solid ${({ theme }) => theme.scrollToTopBorder};
  border-radius: 50%;
  box-shadow: 0 4px 40px 0 ${({ theme }) => theme.scrollToTopShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 70px;
  right: 35px;
  transition: all .3s ease;
  cursor: pointer;
  z-index: 99;
  ${({ show }) => show ? 'opacity: 1; visibility: visible;' : 'opacity: 0; visibility: hidden;'};

  @media screen and (max-width: 992px) {
    width: 50px;
    height: 50px;
    bottom: 96px;
    right: 50px;
    transform: translateX(25px);
  }

  &:hover {
    box-shadow: 0 4px 40px 0 rgba(24, 133, 242, 0.5);
    
    span:last-child {
      animation: pulseAnimation3 1s ease-out forwards infinite;
    }

    span:nth-child(2) {
      animation: pulseAnimation2 1s ease-out forwards 1s infinite;
    }

    span:first-child {
      animation: pulseAnimation1 1s ease-out forwards 2s infinite;
    }
  }

  @keyframes pulseAnimation1 {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 0.75;
    }

    50% {
      opacity: 0.5;
    }

    75% {
      opacity: 0.75;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes pulseAnimation2 {
    0% {
      opacity: 0.75;
    }

    25% {
      opacity: 0.5;
    }

    50% {
      opacity: 0.75;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 0.75;
    }
  }

  @keyframes pulseAnimation3 {
    0% {
      opacity: 0.5;
    }

    25% {
      opacity: 0.75;
    }

    50% {
      opacity: 1;
    }

    75% {
      opacity: 0.75;
    }

    100% {
      opacity: 0.5;
    }
  }
`;

export const StyledBackToTopWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  
  span {
    color: ${({ theme }) => theme.defaultColor};
    font-size: 24px;
    line-height: 0.5;
  }

  span:nth-child(2) {
    opacity: 0.75;
  }

  span:last-child {
    opacity: 0.5;
  }
`;