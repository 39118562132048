import styled from 'styled-components'
import headerDropdownBackground from "../../../assets/images/background-images/header-dropdown/background.png"

export const StyledDropdownMenu = styled.div`
  padding: 12px;
  //display: grid;
  //grid-template-columns: repeat(2, max-content);
  //grid-gap: 15px;
  //grid-template-areas: 'info crypto'
  //                     'document crypto';
  background-color: ${({ theme }) => theme.header.backgroundColor};
  background-image: url(${headerDropdownBackground});
  background-position: center -40px;
  background-repeat: no-repeat;
  box-shadow: 10px 20px 40px 0 ${({ theme }) => theme.header.boxShadow};
  border-radius: 0 0 12px 12px;
  //.info-menu {
  //  grid-area: info;
  //}
  //.document-menu {
  //  grid-area: document;
  //}
  //.crypto-menu {
  //  grid-area: crypto;
  //}
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const StyledInfoMenuWrapper = styled.div`
  .info-menu__title {
    padding-bottom: 15px;
    color: ${({ theme }) => theme.header.text};
    border-bottom: 1px solid ${({ theme }) => theme.neutralColors.sixth};
    font-family: 'Manrope', serif;
    font-size: 14px;
    font-weight: 400;
  }

  .info-menu-item {
    margin: 4px 0;
  }

  //.info-menu-item:first-child {
  //  margin-top: 7px;
  //}

  .info-menu-link {
    padding: 8px;
    font-family: 'Manrope', serif;
    display: inline-flex;
    line-height: 21px;

    &__title {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .info-menu-link_current {
    background-color: ${({ theme }) => theme.hoverColors.fourth};
    border-radius: 12px;
    color: ${({ theme }) => theme.header.text};

    .info-menu-link__title {
      font-weight: 500;
      color: ${({ theme }) => theme.header.text};
    }

    .info-menu-link__icon {
      color: ${({ theme }) => theme.defaultColor};
    }
  }
`;

export const StyledInfoMenuList = styled.ul``;

export const StyledInfoMenuItem= styled.li`
  .info-menu-link {
    width: 100%;
    display: inline-grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 12px;
    align-items: center;
    color: ${({ theme }) => theme.header.linkColor};

    &__icon {
      height: 24px;
      width: 24px;
      color: ${({ theme }) => theme.header.text};
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &:hover {
      background-color:  ${({ theme }) => theme.hoverColors.fourth};
      border-radius: 12px;
      color: ${({ theme }) => theme.header.text};
      
      .info-menu-link__icon {
        color: ${({ theme }) => theme.defaultColor};
      }
    }
  }
`;