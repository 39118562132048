import styled from "styled-components";
import { StyledButton } from "../styles/styled-button";

export const StyledHeader = styled.header`
  width: 100%;
  height: 55px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  transition: all .3s ease;
  ${({ fixed }) => fixed && `
    .header__content {
      height: 63px;
      transition: all .2s ease;
      @media screen and (max-width: 992px) {
        height: 78px;
      }
    }
    .header__wrapper {
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
    }
  `};
  @media screen and (max-width: 992px) {
    position: static;
    height: 78px;
  }
`;

export const StyledHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.header.backgroundColor};
  display: flex;
  align-items: center;
`;

export const StyledHeaderContent = styled.div`
  max-width: 1140px;
  width: 100%;
  height: 86px;
  margin: 0 auto;
  padding: 0 15px;
  color: ${({ theme }) => theme.header.text};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: all .3s ease;

  .header__login {
    max-width: 140px;
    width: 100%;
    line-height: 1;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 992px) {
      max-width: 100px;
    }
  }

  .logo {
    line-height: 0;
  }

  @media screen and (max-width: 992px) {
    .logo {
      order: 2;
    }
    
    .header__login {
      order: 3;
    }
  }
`;

export const StyledLoginButtonWrapper = styled.div`
  ${StyledButton} {
    line-height: 1.5;
    display: ${({ fixed }) => fixed ? "none" : "flex"};

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .header__login-icon {
    display: ${({ fixed }) => fixed ? "inline-flex" : "none"};

    span {
      font-size: 22px;
    }

    &:hover {
      color: ${({ theme }) => theme.defaultColor};
    }

    @media screen and (max-width: 992px) {
      display: inline-flex;
    }
  }
`;
