const dark = {
  body: "#171717",
  bgElements: "#181818",
  borderElements: "#131313",
  lightBg: "#1b1b1b",
  text: "#CBCBCB",
  textOpacity: "#fff",
  textReadonly: "rgba(255, 255, 255, 0.7)",
  textReadonlyHex: "#FFFFFF1A",
  defaultColor: "#1885F2",
  switchPosition: "calc(100% - 19px)",
  navBarBg: "#141414",
  navBarBorder: "#181818",
  border: "#303030",
  activeInputBorder: "#fff",
  requisitionStatusDefault: "rgba(255, 255, 255, 0.5)",
  hoverColor: "#1b2e3180",
  hoverShadow: "#c9c9c9", // TODO: TEMPORARY
  skeletonBg: "rgba(255, 255, 255, 0.11)",
  skeletonBgGradient: "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.05), transparent)",
  loadingForm: "rgba(10, 10, 10, 0.5)",
  errorRow: "#5c1717",
  disabledText: "rgba(255,255,255,0.75)",
  backgroundDark: "#212121",
  white: "#ffffff",
  developedBg: "#4A4E56",
  primaryColors: {
    first: "#1885F2",
    second: "#349AFF"
  },
  neutralColors: {
    first: "#F7F7F7",
    second: "#CBCBCB",
    third: "#AAAAAA",
    fourth: "#4A4E56",
    fifth: "#172040",
    sixth: "rgba(242, 242, 242, 0.2)",
    text: {
      first: "#1C274C",
      third: "#AAA"
    },
    bg: {
      fifth: "#F2F2F2",
    }
  },
  dangerColors: {
    first: "#E82020",
    second: "#FF6E65",
    third: "rgba(250, 89, 79, 0.2)",
    fourth: "#444447",
    fifth: "#FDEDED"
  },
  successColors: {
    first: "#12BB74",
    second: "#42C888",
    third: "rgba(25, 190, 111, 0.2)",
    fourth: "#17584D",
    fifth: "#ECFAF4"
  },
  warningColors: {
    first: "#FFB800",
    second: "#FFAB2E",
    third: "rgba(255, 153, 0, 0.2)",
    fourth: "#455137",
    fifth: "#FFF9EB"
  },
  infoColors: {
    first: "#338DE2i",
    second: "#42A3FF",
    third: "rgba(66, 163, 255, 0.2)",
    fourth: "#20536B",
    fifth: "#EFF6FD",
    sixth: "rgba(51,141,226,0.2)"
  },
  hoverColors: {
    first: "#349AFF",
    second: "#E7EEF3",
    third: "#E5EDF3",
    fourth: "rgba(4, 96, 169, 0.2)",
    fifth: "rgba(100, 100, 100, 0.2)"
  },
  /* Header colors */
  header: {
    backgroundColor: "#212121",
    mobileBackgroundColor: "#212121",
    text: "#F7F7F7",
    mobileText: "#F7F7F7",
    linkColor: "#CBCBCB",
    profileBackground: "rgba(24, 133, 242, 0.1)",
    boxShadow: "rgba(4, 96, 169, 0.12)"
  },
  /* Scrollbar background */
  scrollbarBackground: "#CCCCCC",
  /* Images box-shadow */
  imageBoxShadow: "rgba(4, 96, 169, 0.14)",
  /* Scroll to top box-shadow */
  scrollToTopBorder: "#1885F2",
  scrollToTopShadow: "rgba(24, 133, 242, 0.25)",
  scrollToTopHoverShadow: "rgba(24, 133, 242, 0.5)",
  /* Different blocks background */
  blockBackground: "#172040",
  selectionColors: "rgba(4, 96, 169, 1)"
};

export default dark;