import React from "react";
import { StyledMerchantsWrapper } from "./styled-footer";

const FooterMerchants = () => {
  return (
    <StyledMerchantsWrapper>
      <div className="merchant-item">
        <span className="merchant-sprite-icon bg-visa" />
      </div>
      <div className="merchant-item">
        <span className="merchant-sprite-icon bg-visa_secure" />
      </div>
      <div className="merchant-item">
        <span className="merchant-sprite-icon bg-mastercard" />
      </div>
      <div className="merchant-item">
        <span className="merchant-sprite-icon bg-mastercard_id_check" />
      </div>
    </StyledMerchantsWrapper>
  );
};

export default FooterMerchants;