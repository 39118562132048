import styled from 'styled-components';

import leftBottomDecorationImage from "./../../assets/images/decoration-1.png";
import rightTopDecorationImage from "./../../assets/images/decoration-2.png";

const tableSize = {
  size: ''
}

const changeContainerSize = (size) => {
  switch (size) {
    case 'xl':
      return `
        max-width: 1920px
      `
    default:
      return `
        max-width: 1140px
      `
  }
}

export const StyledContainer = styled('div', tableSize)`
  --header: 78px;
  --footer: 853px;
  ${({ size }) => changeContainerSize(size)};
  width: 100%;
  margin: 0 auto;
  padding: ${({ wrapper }) => wrapper !== "content" ? "110px 15px 0" : "0 15px"};
  ${({ wrapper }) => wrapper !== "content" && `min-height: calc(100vh - var(--header) - var(--footer))`};
  ${({ wrapper }) => wrapper !== "content" && `padding-bottom: 30px`};
  position: relative;

  ${({ leftBottomDecoration }) => leftBottomDecoration && `

    &:before {
      content: "";
      position: absolute;
      bottom: -475px;
      left: -520px;
      background-image:url(${leftBottomDecorationImage});
      background-repeat: no-repeat;
      width: 844px;
      height: 844px;
      z-index: 1px;
      
      @media screen and (max-width: 768px) {
        all: unset;
      }
    }
  `};

  ${({ rightTopDecoration }) => rightTopDecoration && `
    &:after {
      content: "";
      position: absolute;
      top: -240px;
      right: -540px;
      background-image:url(${rightTopDecorationImage});
      background-repeat: no-repeat;
      width: 1273px;
      height: 648px;
      transform: rotate(-40deg);
      z-index: 1px;
      pointer-events: none;

      @media screen and (max-width: 768px) {
        all: unset;
      }
    }
  `};
  
  @media screen and (max-width: 992px) {
    padding: 32px 15px 0;
    ${({ wrapper }) => wrapper !== 'content' && `height: auto`};
    ${({ wrapper }) => wrapper !== 'content' && `padding-bottom: 50px`};
  }
`;
